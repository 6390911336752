.phone {
    --box-shadow: rgba(0, 0, 0, 0.25);

    position: absolute;
    top: 50vh;
    left: 50%;
    margin: auto;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    z-index: 11; /* higher than hero, but below panels */
    box-shadow: 0 0 0 200vh var(--box-shadow);

    @media (--rnb-mq-large) {
        left: 75%;
        width: 225px;
        height: 470px;
        flex-direction: column;
        box-shadow: -25vw 0 0 200vh var(--box-shadow);
    }

    @media (min-width: 1440px) {
        left: 50%;
        width: 260px;
        height: 525px;
        box-shadow: 0 0 0 200vh var(--box-shadow);
    }
}
