.panels {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
}

.panelMenuContainer {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
