.carouselContent {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--rnb-colour-white);
    width: 100%;
    height: 100%;

    --score-font-size: 3rem;

    @media (--rnb-mq-huge) {
        --score-font-size: 3.2rem;
    }
}

.label {
    max-width: 100%;
    text-align: center;
    font-weight: bold;
}

.partnerLogos {
    --logo-width: 80%;

    width: var(--logo-width);
}
