.item {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.itemEnter {
    opacity: 0;
    transform: translateX(100%);
}

.reverseEnter {
    opacity: 0;
    transform: translateX(-100%);
}

.itemEnterActive {
    opacity: 1;
    transform: translateX(0%);
    transition: transform var(--rnb-timing-normal) ease-in-out, opacity 1500ms;
}

.itemExit {
    opacity: 1;
    transform: translateX(0%);
}

.itemExitActive {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform var(--rnb-timing-normal) ease-in-out, opacity var(--rnb-timing-normal);
}

.reverseExitActive {
    opacity: 0;
    transform: translateX(100%);
    transition: transform var(--rnb-timing-normal) ease-in-out, opacity var(--rnb-timing-normal);
}

.indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 8%;
    padding-left: 5px;
    padding-right: 5px;
}
