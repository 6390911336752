.panel {
    --longTransition: 750ms;
    --shortTransition: 300ms;
    --panelHeightOverlap: 115vh;
    --zIndexAboveContent: 14;

    transition: opacity var(--longTransition);
    height: var(--panelHeightOverlap);
    position: relative;
    opacity: 1;
    z-index: var(--zIndexAboveContent);
}

.panelContent {
    --maxContainerSize__5: 1440px;
    --zIndexAboveBackgrounds: 13;

    max-width: var(--maxContainerSize__5);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: var(--zIndexAboveBackgrounds);
    position: relative;

    @media (--rnb-mq-large) {
        justify-content: initial;
    }

    @media (--rnb-mq-huge) {
        background: none;
    }
}

.content {
    --wrapBeforePhoneComponent: 37.5%;
    --wrapBeforePhoneComponentM: 50%;

    position: relative;
    transition: opacity var(--shortTransition);
    display: flex;
    flex-direction: column;
    color: var(--rnb-colour-white);
    align-items: center;
    margin-top: 0;
    padding: 0 var(--rnb-spacing-medium);

    @media (--rnb-mq-small) {
        padding: var(--rnb-spacing-big);
    }

    @media (min-height: 550px) {
        margin-top: -15vh;
    }

    @media (--rnb-mq-large) {
        max-width: var(--wrapBeforePhoneComponentM);
        margin-top: 0%;
        margin-left: var(--rnb-spacing-huge);
        align-items: flex-start;
        padding: 0;
    }

    @media (min-width: 1440px) {
        width: var(--wrapBeforePhoneComponent);
        max-width: 500px;
    }
}

.background {
    --darkerBlue: rgb(16, 23, 32) 100%;
    --zIndexAboveNextPanel: 10 /* lower than panels + hero phone */;

    position: relative;
    height: 100%;
    width: 100%;
    z-index: var(--zIndexAboveNextPanel);
    top: 15vh;
}

.disableStaticBackground {
    background: linear-gradient(var(--darkerBlue) 0%, transparent 75%);
}

.backgroundColor {
    --bgBlue: rgb(38, 54, 72);
    --bgBlueStart: rgb(38, 54, 72) 0%;
    --bgBlueEnd: rgb(16, 23, 32) 100%;

    position: fixed;
    z-index: 9;
    height: 100vh;
    width: 100%;
    top: 0;
}

.disableStaticBackgroundColor {
    background-color: var(--bgBlue);
    background-image: radial-gradient(ellipse at center, var(--bgBlueStart), var(--bgBlueEnd));
}

.appStoreQr {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.creditBrokerDisclaimer {
    @media (--rnb-mq-lt-medium) {
        display: flex;
        justify-content: center;
    }

    @media (--rnb-mq-phablet) {
        margin-left: var(--rnb-spacing-small);
    }

    @media (--rnb-mq-large) {
        margin-left: 0;
    }
}

.logoContainer {
    color: var(--rnb-colour-white);
    margin-bottom: var(--rnb-spacing-big);
    display: flex;
    flex-direction: column;
    font-size: var(--rnb-font-size-medium);

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-large);
    }
}

.text {
    text-align: center;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.heading {
    margin-bottom: var(--rnb-spacing-micro);
}

.blurb {
    margin-bottom: var(--rnb-spacing-big);
}

.heroAppLogos {
    --zIndexAboveEverything: 15;

    display: none;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    right: var(--rnb-spacing-big);
    z-index: var(--zIndexAboveEverything);
    position: absolute;
    top: 90vh;
    transition: opacity var(--shortTransition);

    @media (--rnb-mq-large) {
        display: flex;
    }
}

.viewportM {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

@media (--rnb-mq-medium) {
    .viewportXS {
        display: none;
    }
}

.fade {
    transition: opacity var(--longTransition);
    opacity: 1;

    .fadeOutContent & {
        opacity: 0;
    }
}

.delay10 {
    transition-delay: 0.15s;
}

.delay20 {
    transition-delay: 0.3s;
}

.marketRingLayer {
    display: flex;
    justify-content: center;
    transition: opacity 350ms;
}

.marketScoreRingMobile {
    width: var(--scoreRingMobileWidth);
    height: var(--scoreRingMobileHeight);
    position: absolute;
    margin-top: 10vh;

    @media (--rnb-mq-large) {
        margin-top: 10vh;
    }

    @media (--rnb-mq-large) {
        display: none;
    }
}

.trustPilotContainer {
    margin-left: -1rem; /* we don't have control over the trust pilot content */
}
