/* todo: limitation rendering this implementation means we cant change css for each panel, or hide the phone in the footer */
.phone {
    --phoneContentWidth: 80%;
    --circleRadius: 50%;
    --blurBackroundLight: 6px;
    --blurBackroundStrong: 12px;
    --phoneShapeWidth: 225px;
    --phoneShapeHeight: 470px;
    --phoneShapeHeightLarger: 525px;
    --phoneShapeWidthLarger: 260px;
    --phoneShapeBorder: 25px;
    --animateDuration: 0.7s;
    --animateOpacityStart: 0.2;

    position: absolute;
    width: 225px;
    height: 470px;
    margin: auto;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    top: 50vh;
    display: none;
    z-index: 15;
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.4);
    transition: opacity 350ms;
    opacity: 1;

    @media (--rnb-mq-large) {
        align-items: center;
        justify-content: center;
        left: 75%;
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 1440px) {
        left: 50%;
        width: 260px;
        height: 525px;
        display: flex;
        flex-direction: column;
    }
}

.scoreRingBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: var(--circleRadius);
    transform: translateY(-50%) translateX(-50%);
    background-color: rgba(var(--rnb-colour-black-rgb), var(--animateOpacityStart));
}

.scoreBackground {
    background-image: url('./assets/score_panel_image_l.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    content: '';
    width: var(--phoneShapeWidth);
    height: var(--phoneShapeHeight);
    border-radius: var(--phoneShapeBorder);
    transition: opacity 700ms;
    opacity: 0;

    @media (min-width: 1440px) {
        width: var(--phoneShapeWidthLarger);
        height: var(--phoneShapeHeightLarger);
    }
}

.phoneContent {
    width: var(--phoneContentWidth);
    border-radius: var(--circleRadius);
}

.fadeOpacity {
    animation: fadeOpacity var(--animateDuration);
    animation-fill-mode: forwards;
}

.hidden {
    opacity: 0;
}

.shown {
    opacity: 1;
}
