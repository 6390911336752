.report {
    --phoneOutlineRadius__2: 25px;
    --phoneTopHeight: 30%;
    --phoneBodyHeight: 70%;
    --sMMaxWidth: 365px;
    --imageSize: 80%;
    --imageSizeLarger: 90%;
    --background-color-t: transparent;

    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 70%;
    background-color: var(--background-color-t);
    border-radius: var(--phoneOutlineRadius__2);

    svg {
        width: 100%;
        height: auto;
    }

    @media (--rnb-mq-medium) {
        width: 30%;
        margin: auto;
        justify-content: space-evenly;
    }

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
        background-color: var(--rnb-colour-white);
    }
}

.yourScore {
    background-image: url('./assets/report_panel_image_xl.jpg');
    background-position-x: center;
    background-size: cover;
    border-top-right-radius: var(--phoneOutlineRadius__2);
    border-top-left-radius: var(--phoneOutlineRadius__2);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--rnb-colour-white);
    height: 15vh;
    margin: auto;
    width: var(--imageSize);
    max-width: var(--sMMaxWidth);

    p {
        color: var(--rnb-colour-white-alpha-64);
    }

    @media (--rnb-mq-medium) {
        width: 100%;
        height: 12.5vh;
    }

    @media (--rnb-mq-large) {
        height: var(--phoneTopHeight);
        margin-bottom: 0;
        width: 100%;
    }
}

.insightsImage {
    margin: auto;
    margin-top: var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-medium);
    max-width: var(--sMMaxWidth);
}

.paymentHistory {
    width: var(--imageSize);
    margin: auto;
    max-width: var(--sMMaxWidth);

    @media (--rnb-mq-large) {
        margin: auto;
    }
}

.thisMonthImage {
    width: var(--imageSize);
    margin: auto;
    max-width: var(--sMMaxWidth);

    @media (--rnb-mq-medium) {
        width: 100%;
        margin-top: var(--rnb-spacing-large);
    }

    @media (--rnb-mq-large) {
        margin-left: 0;
        margin-top: 0;
        background-color: var(--rnb-colour-black-tint-4);
    }
}

.paymentBackground {
    width: var(--imageSize);
    max-width: var(--sMMaxWidth);
    height: 35vh;
    background-color: var(--rnb-colour-white);
    border-radius: 8px;
    margin: auto;
    margin-bottom: var(--rnb-spacing-tiny);

    @media (--rnb-mq-medium) {
        height: 30vh;
    }

    @media (--rnb-mq-large) {
        width: var(--imageSizeLarger);
        margin-left: 4%;
        margin-top: 0;
    }
}

.visibleSM {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }

    @media (--rnb-mq-large) {
        display: none;
    }
}

.hiddenSM {
    @media (--rnb-mq-medium) {
        display: none;
    }

    @media (--rnb-mq-large) {
        display: block;
    }
}

.reportsPhone {
    border-radius: var(--phoneOutlineRadius__2);
    margin: auto;
    width: 70%;
    height: auto;

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
    }
}
