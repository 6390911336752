@keyframes offset-to-zero {
    to {
        stroke-dashoffset: 0;
    }
}

.innerRing {
    fill: transparent;
    cursor: pointer;
}

.isThemeDark {
    stroke: var(--rnb-colour-white);

    &.isActive {
        fill: var(--rnb-colour-white);
    }
}

.isThemeLight {
    stroke: var(--rnb-colour-midnight);

    &.isActive {
        fill: var(--rnb-colour-midnight);
    }
}

.outerRing {
    fill: transparent;
    transform-origin: center;
    transform: rotate(-90deg);
    animation-name: offset-to-zero;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.isPaused {
    animation-play-state: paused;
}
