.container {
    --bgGreenStart: rgba(198, 226, 236, 0.3) 30%;
    --bgGreenEnd: rgba(225, 246, 248, 0.3) 100%;
    --bgPinkStart: rgb(255, 255, 255) 0%;
    --bgPinkEnd: rgb(235, 224, 230) 100%;
    --bgBlue: rgb(38, 54, 72);
    --bgBlueStart: rgba(38, 54, 72, 0.56) 0%;
    --bgBlueEnd: rgba(16, 23, 32, 0.06) 100%;
    --bgPurpleStart: rgb(248, 248, 248) 0%;
    --bgPurpleEnd: rgb(221, 221, 231) 100%;
    --semiDarkBlue: rgba(38, 54, 72, 0.6) 0%;
    --semiDarkerBlue: rgba(16, 23, 32, 0.6) 100%;
    --max-container-size: 1440px;
    --zIndexBackgroundColor: 12;
    --zIndexBackgroundImg: 12;
    --z-Index-phone-content: 13;
    --zIndexAboveContent: 14;
    --zTopIndex: 15;
    --protectBgSize: 400px;
    --longTransition: 750ms;
    --shortTransition: 300ms;
    --panelHeightOverlap: 115vh;

    position: relative;
    transition: opacity var(--longTransition);
    height: 100%;
    opacity: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.blurbContainer {
    margin: 0 auto;
    max-width: var(--max-container-size);
    position: relative;
    padding: 0 var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        display: block;
    }
}

.blurb {
    width: 100%;
    z-index: var(--zIndexAboveContent);
    margin: 10% 0;
    position: relative;

    @media (--rnb-mq-large) {
        margin: 0;
    }
}

.circleBackground {
    display: none;
    height: 100vw;
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: var(--rnb-colour-white);
    border-radius: 50%;
    margin: 57.5vh 0;

    @media (--rnb-mq-medium) {
        height: var(--protectBgSize);
        width: var(--protectBgSize);
    }

    @media (--rnb-mq-large) {
        display: block;
        left: calc(75vw + 4%);
    }

    @media (min-width: 1440px) {
        left: calc(50vw + 4%);
        margin: 50vh 0;
    }
}

.bugPosition {
    width: 35px;
    display: none;
    margin-top: 5%;
    margin-left: 5%;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.fingerPrintPosition {
    display: none;
    margin-left: 74%;
    margin-top: 68%;
    width: 70px;

    @media (--rnb-mq-large) {
        display: block;
    }

    @media (min-width: 1440px) {
        width: 80px;
        margin-left: 78%;
        margin-top: 70%;
        display: block;
    }
}

.mobileOverFlow {
    overflow: hidden;

    @media (--rnb-mq-large) {
        overflow: unset;
    }
}

.phone {
    position: absolute;
    display: none;
    top: 50%;
    width: 225px;
    height: 470px;
    margin: auto;
    border-radius: 25px;
    transform: translate(-50%, -50%);
    z-index: var(--zTopIndex);
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.4);

    @media (--rnb-mq-large) {
        display: block;
        align-items: center;
        justify-content: center;
        left: 75%;
        flex-direction: column;
    }

    @media (min-width: 1440px) {
        left: 50%;
        width: 260px;
        height: 525px;
        display: flex;
        flex-direction: column;
    }
}

.phoneContent {
    width: 100%;
    z-index: var(--z-Index-phone-content);
    margin: 20% 0 40%;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.combinedContentBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: var(--z-Index-phone-content);
    margin: 0 0 15%;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.mobileBlurb {
    margin: 0 auto;
    max-width: var(--max-container-size);
    padding: 0 var(--rnb-spacing-medium);
}

.marketBlurb {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.transform {
    transition: opacity 350ms;
}

.transformSlow {
    transition: opacity 750ms;
}

.phoneTransition {
    transition-delay: 0.15s;
}

.background {
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 1;
    z-index: -1;
}

.backgroundColor {
    position: absolute;
    z-index: var(--zIndexBackgroundColor);
    opacity: 1;
}

.backgroundImg,
.backgroundImgShade {
    position: absolute;
    z-index: var(--zIndexBackgroundImg);

    img {
        height: 100%;
        width: 100%;
    }
}

.mobileBackground {
    position: absolute;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.isLight {
    background-color: var(--rnb-colour-white);

    .backgroundColor {
        background-color: var(--rnb-colour-white);

        &.pink {
            background-image: radial-gradient(ellipse at center, var(--bgPinkStart), var(--bgPinkEnd));
        }

        &.green {
            background-image: radial-gradient(ellipse at center, var(--bgGreenStart), var(--bgGreenEnd));
        }

        &.purple {
            background-image: radial-gradient(ellipse at center, var(--bgPurpleStart), var(--bgPurpleEnd));
        }
    }
}

.isDark {
    background-color: var(--bgBlue);

    .backgroundColor {
        background-color: var(--bgBlue);

        &.blue {
            background-image: radial-gradient(ellipse at center, var(--bgBlueStart), var(--bgBlueEnd));
        }
    }

    .backgroundImgShade {
        background-image: radial-gradient(ellipse at center, var(--semiDarkBlue), var(--semiDarkerBlue));

        @media (--rnb-mq-large) {
            background-image: none;
        }
    }
}
