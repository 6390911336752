.panel {
    position: relative;
    background: var(--rnb-colour-black-alpha-8);

    @media (--rnb-mq-large) {
        height: 100vh;
    }

    &.hero {
        height: 110vh;

        @media (--rnb-mq-large) {
            height: 100vh;
        }
    }
}

.staticBackgroundImage {
    box-shadow: inset 0 0 20rem 7px rgba(0, 0, 0, 0.3);

    /* The default background image  */
    background: url('https://images.ctfassets.net/8qiqw3a16vjh/4jB4uKbXqWWvx3iE0qhntF/ba96d858fa679c7440efc320edf43cdc/background_375_small.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    z-index: 0;
    overflow: hidden;

    @media (--rnb-mq-phablet) {
        background: url('https://images.ctfassets.net/8qiqw3a16vjh/69xhzGull0n6qXEwRBnxDO/3ae4c825a7f3d9c3e579f77fa32974f7/background_520_phablet.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        overflow: hidden;
    }

    @media (--rnb-mq-medium) {
        background: url('https://images.ctfassets.net/8qiqw3a16vjh/6cmfIKiMAofn3ZwMTKZAxi/a30f05f11ddc9209ac6ec0cc04516691/background_834_medium.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        overflow: hidden;
    }

    @media (--rnb-mq-large) {
        background: url('https://images.ctfassets.net/8qiqw3a16vjh/3x6wwAHcwlNe24yGYf3RLK/306553d80012c1b110d3b4c9a0740ed8/background_119_large.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        overflow: hidden;
    }

    @media (--rnb-mq-huge) {
        background: url('https://images.ctfassets.net/8qiqw3a16vjh/1tjqLM5ojLMgWM6kcDP17N/0fbd498ec77366cf63e220f221c22a3b/background_1440_huge.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        overflow: hidden;
    }
}
