.panelMenu {
    --opaqueMenuText: rgba(255, 255, 255, 0.4);
    --menuOffsetXSTop: 75vh;
    --menuOffsetXXLLeft: 80vw;
    --menuOffsetXLLeft: 90vw;
    --menuDotSize: 5px;
    --menuDotSizeIsActive: 9px;
    --zIndexAbovePanels: 14;
    --transitionDuration: 0.4s;

    position: fixed;
    top: var(--menuOffsetXSTop);
    width: 100%;
    z-index: var(--zIndexAbovePanels);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--rnb-mq-large) {
        top: 50vh;
        left: var(--menuOffsetXLLeft);
        transform: translate(-50%, -50%);
        width: 10vw;
    }

    @media (min-width: 1440px) {
        left: var(--menuOffsetXXLLeft);
        width: 30vw;
        align-items: flex-start;
    }
}

.menuItem {
    width: var(--menuDotSize);
    height: var(--menuDotSize);
    border-radius: 50%;
    vertical-align: middle;
    background-color: var(--rnb-colour-white-alpha-32);
    margin: var(--rnb-spacing-tiny) auto 0;

    > * {
        display: none;

        @media (min-width: 1440px) {
            display: block;
        }
    }

    &.isActive {
        background-color: var(--rnb-colour-white);
        width: var(--menuDotSizeIsActive);
        height: var(--menuDotSizeIsActive);
        transition: all var(--transitionDuration) ease;

        &.isLight {
            background-color: var(--rnb-colour-white);
        }

        &.isDark {
            background-color: var(--rnb-colour-black);
        }
    }

    &.isLight {
        background-color: var(--opaqueMenuText);
    }

    &.isDark {
        background-color: var(--rnb-colour-black-alpha-32);
    }
}

.menuList {
    visibility: hidden;

    @media (--rnb-mq-medium) {
        visibility: visible;
    }
}

.nextPanelButton {
    display: flex;
    align-items: center;
    margin-top: var(--rnb-spacing-big);
    color: var(--rnb-colour-white);
    padding: 0;
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    transition: all var(--transitionDuration) ease;

    &.isDark {
        color: var(--rnb-colour-midnight);
    }
}

.nextPanelLabel {
    display: none;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    @media (min-width: 1440px) {
        display: block;
    }
}

.arrow {
    @media (min-width: 1440px) {
        margin-right: var(--rnb-spacing-medium);
    }
}

.tagPosition {
    display: inline;
    margin-left: var(--rnb-spacing-small);
}

.bounce {
    animation: bounce;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-delay: 3000ms;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(-8px);
    }
}

@media (min-width: 1440px) {
    .menuItem {
        margin-bottom: var(--rnb-spacing-micro);
        width: auto;
        height: auto;
        background-color: initial; /* stylelint-disable-line sh-waqar/declaration-use-variable */
        margin-top: 0;

        &:hover:not(.isActive) {
            cursor: pointer;
            text-decoration: underline;
        }

        &.isActive {
            width: auto;
            height: auto;

            &.isDark,
            &.isLight {
                background-color: initial; /* stylelint-disable-line sh-waqar/declaration-use-variable */
            }

            &.isDark {
                color: var(--rnb-colour-midnight);
            }

            &.isLight {
                color: var(--rnb-colour-white);
            }
        }

        &.isDark {
            background-color: initial; /* stylelint-disable-line sh-waqar/declaration-use-variable */
            color: var(--rnb-colour-black-alpha-32);
        }

        &.isLight {
            background-color: initial; /* stylelint-disable-line sh-waqar/declaration-use-variable */
            color: var(--opaqueMenuText);
        }
    }
}
