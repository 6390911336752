.container {
    --largeWidth: 58%;
    --containerWidth: 37.5%;
    --containerMaxWidth: 500px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: var(--rnb-spacing-micro);
    z-index: 9;

    @media (--rnb-mq-small) {
        padding: var(--rnb-spacing-big);
    }

    @media (--rnb-mq-large) {
        justify-content: initial;
        height: 100%;
        width: var(--largeWidth);
        text-align: left;
        padding: 0;
    }

    @media (min-width: 1440px) {
        width: var(--containerWidth);
        max-width: var(--containerMaxWidth);
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    max-width: 100%;
    z-index: var(--contentForeground);
    color: var(--rnb-colour-black-alpha-64);
    will-change: transform, opacity;

    :not(:last-child) {
        margin-bottom: var(--rnb-spacing-large);
    }

    &.white {
        color: var(--rnb-colour-white);
    }

    @media (--rnb-mq-medium) {
        width: var(--largeWidth);

        :not(:last-child) {
            margin-bottom: var(--rnb-spacing-big);
        }
    }

    @media (--rnb-mq-large) {
        margin-left: var(--rnb-spacing-huge);
        align-items: flex-start;
        width: auto;
    }
}

.ctaAlignment {
    @media (--rnb-mq-large) {
        display: flex;
    }
}

.ctaSpacing {
    @media (--rnb-mq-large) {
        margin-left: var(--rnb-spacing-tiny);
    }
}

.center {
    display: flex;
    justify-content: center;
}

.viewportM {
    display: none;
}

.fade {
    transition: opacity var(--longTransition);
    opacity: 1;

    .fadeOutContent & {
        opacity: 0;
    }
}

.delay20 {
    transition-delay: 0.3s;
}

@media (--rnb-mq-medium) {
    .viewportXS {
        display: none;
    }

    .viewportM {
        display: block;
    }
}
