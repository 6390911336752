.protect {
    --phoneOutlineRadius: 25px;
    --sMMaxWidth: 365px;
    --imageSize: 80%;
    --imageSizeLarger: 90%;
    --imageSizeLargest: 95%;
    --headerPadding: 1px;

    height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: transparent; /* stylelint-disable-line sh-waqar/declaration-use-variable */
    border-radius: var(--phoneOutlineRadius);

    svg {
        width: 100%;
        height: auto;
    }

    @media (--rnb-mq-medium) {
        width: 60%;
        margin: auto;
        height: 80vh;
        justify-content: center;
    }

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
        background-color: var(--rnb-colour-white);
    }
}

.header {
    padding: var(--headerPadding);
}

.cardImage {
    margin-bottom: 0;
    align-self: center;
    max-width: calc(var(--sMMaxWidth) - var(--rnb-spacing-small));
    width: var(--imageSizeLargest);

    @media (--rnb-mq-large) {
        margin-bottom: 0;
        width: var(--imageSizeLarger);
    }
}

@keyframes floatIn {
    100% {
        transform: translateY(0);
        opacity: 1;
    }

    60% {
        transform: translateY(8px);
        opacity: 1;
    }

    40% {
        transform: translateY(16px);
        opacity: 0.5;
    }

    20% {
        transform: translateY(30px);
        opacity: 0.25;
    }

    0% {
        transform: translateY(50px);
        opacity: 0;
    }
}
