.parallaxContainer {
    --scoreRingMobileBottomGutter: 0;
    --fontSizeH3: 3.25rem;
    --scoreRingMobileWidth: 200px;
    --scoreRingMobileHeight: 200px;

    width: 100%;
    height: 115vh;
    position: fixed;
    z-index: -1;
    overflow: hidden;

    &::after {
        content: '';
        background: linear-gradient(to bottom, var(--rnb-colour-black-alpha-32) 0%, var(--rnb-colour-transparent) 50%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: var(--rnb-zindex-rise);
    }
}

.parallaxContainer--au {
    --scoreRingMobileBottomGutter: -200px;
}

.parallaxLayer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.darkenImage {
    filter: brightness(86%);
}

.ringLayer {
    display: flex;
    justify-content: center;
    transition: opacity 350ms;
}

.fullHeight {
    height: 115vh;
}

.scoreRingMobile {
    width: var(--scoreRingMobileWidth);
    height: var(--scoreRingMobileHeight);
    bottom: var(--scoreRingMobileBottomGutter);
    position: absolute;

    @media (--rnb-mq-large) {
        display: none;
    }
}
