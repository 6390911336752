.carouselContent {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--rnb-colour-white);
    width: 100%;
    height: 100%;

    --score-font-size: 3rem;

    @media (--rnb-mq-huge) {
        --score-font-size: 3.2rem;
    }
}

.score {
    color: var(--rnb-colour-sea-tint-64);
    font-size: var(--score-font-size);
    font-weight: var(--rnb-font-weight-thin);
    margin: 6px 0;
}
