.carouselContent {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--rnb-colour-white);
    width: 100%;
    height: 100%;

    --score-font-size: 2.7rem;

    @media (--rnb-mq-huge) {
        --score-font-size: 3.2rem;
    }
}

.primaryLabel,
.outOfText,
.tertiaryLabel {
    max-width: 100%;
    padding: 0 var(--rnb-spacing-big);
    text-align: center;
}

.score {
    color: var(--rnb-colour-sea-tint-64);
    font-size: var(--score-font-size);
    font-weight: var(--rnb-font-weight-thin);
    margin: 6px 0;
}

.maxScore {
    font-weight: bold;
}

.creditScore {
    font-weight: bold;
}

.tertiaryLabel {
    color: var(--rnb-colour-sea-tint-64);
    font-weight: bold;
}

.fadeOpacity {
    animation: fadeOpacity var(--animateDuration__2);
}

@keyframes fadeOpacity {
    0% {
        opacity: var(--animateOpacityStart__2);
    }

    50% {
        opacity: var(--animateOpacityMiddle);
    }

    100% {
        opacity: var(--animateOpacityEnd);
    }
}
