.container {
    margin-top: var(--rnb-spacing-large);
    padding: var(--rnb-spacing-large);
    padding-bottom: var(--rnb-spacing-small);
    border-radius: var(--rnb-border-radius-large);
    background-image: url('https://media.clearscore.com/au/images/janex/intro-banner/small@3x.jpg');

    @media (--rnb-mq-phablet) {
        padding: var(--rnb-spacing-big) var(--rnb-spacing-huge);
    }
}

.title {
    font-size: var(--rnb-font-size-small);
    margin-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-phablet) {
        font-size: var(--rnb-font-size-large);
        font-weight: var(--rnb-font-weight-bold);
        margin-bottom: var(--rnb-spacing-large);
    }
}

.description {
    font-size: var(--rnb-font-size-mini);
    margin-bottom: var(--rnb-spacing-small);
    line-height: var(--rnb-line-height-text);

    @media (--rnb-mq-phablet) {
        font-size: var(--rnb-font-size-medium);
        margin-bottom: var(--rnb-spacing-medium);
    }
}
