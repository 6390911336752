.emailForm {
    display: flex;
}

.input {
    margin-right: var(--rnb-spacing-tiny);
}

.button {
    margin: var(--rnb-spacing-small) auto;
}

/* design doesnt quite work with rainbow design system */

/* we need to override the padding to be a bit smaller */
div[data-qa='error-message'] {
    padding-left: var(--rnb-spacing-tiny);
}

.submitLabel {
    --rnb-spacing-large: 0;

    display: block;

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.submitLabelDesktop {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

@media (--rnb-mq-large) {
    .input {
        --inputWidth: 240px;

        width: var(--inputWidth);
    }
}
