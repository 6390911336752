.carouselContent {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--rnb-colour-white);
    width: 100%;
    height: 100%;
}

.primaryLabel,
.score,
.secondaryLabel {
    max-width: 100%;
    padding: 0 var(--rnb-spacing-big);
    text-align: center;
}

.primaryLabel {
    font-weight: bold;
}

.score {
    margin: 6px 0;
}

.fadeOpacity {
    animation: fadeOpacity var(--animateDuration__2);
}

@keyframes fadeOpacity {
    0% {
        opacity: var(--animateOpacityStart__2);
    }

    50% {
        opacity: var(--animateOpacityMiddle);
    }

    100% {
        opacity: var(--animateOpacityEnd);
    }
}
