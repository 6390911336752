.phoneContent {
    --verticalIconSizeLarger: 140px;

    width: 90%;
    margin: 0 auto;

    @media (--rnb-mq-medium) {
        width: 50%;
    }
}

.marketPhoneContent {
    width: 100%;
    margin: 0 auto;

    & p {
        margin: auto;
        width: 95%;
    }
}

.phone {
    --phoneBorderRadius: 25px;
    --verticalIconSizeLarger: 140px;

    width: 100%;
    height: 100%;
    background-color: var(--rnb-colour-white);
    border-radius: var(--phoneBorderRadius);
    overflow: hidden;
}

.phoneTop {
    --phoneHeadingHeight: 12.5%;
    --phoneOutlineRadius: 25px;
    --phoneTopLight: rgb(153, 174, 180);
    --phoneTopDark: rgb(142, 165, 161);

    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(-180deg, var(--phoneTopLight) 0%, var(--phoneTopDark) 100%);
    width: 100%;
    text-align: center;
    color: var(--rnb-colour-white);
    height: var(--phoneHeadingHeight);
    border-top-right-radius: var(--phoneOutlineRadius);
    border-top-left-radius: var(--phoneOutlineRadius);
}

.offerCards {
    --verticalCardsSpacing: 5px;
    --verticalCardsTopLarge: 5rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--verticalCardsSpacing);
    grid-column-gap: calc(var(--verticalCardsSpacing) * 2);
    text-align: center;
    width: auto;

    @media (--rnb-mq-medium) {
        margin-top: var(--verticalCardsTopLarge);
    }

    @media (--rnb-mq-large) {
        margin-top: 0;
    }
}

.fullWidthIcons {
    @media (--rnb-mq-large) {
        grid-template-columns: 1fr;

        .verticalIcon {
            width: var(--verticalIconSizeLarger);
            height: var(--verticalIconSizeLarger);
        }
    }
}

.phoneInnerContent {
    padding: var(--rnb-spacing-large) var(--rnb-spacing-tiny) 0;
    background-image: radial-gradient(ellipse at top, rgba(198, 226, 236, 0.3) 30%, rgba(225, 246, 248, 0.3) 100%);
}

.heading {
    font-size: var(--rnb-font-size-medium);
    text-align: center;
    color: var(--rnb-colour-black-alpha-64);
    margin-bottom: var(--rnb-spacing-super);
}

.isWhite {
    color: var(--rnb-colour-white);
}

.verticalIcon {
    --verticalIconBoxShadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    --verticalIconImageHeight: 40%;

    box-shadow: var(--verticalIconBoxShadow);
    background-color: var(--rnb-colour-white);
    border-radius: var(--rnb-border-radius-small);
    width: 100%;
    height: var(--verticalIconSizeLarger);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--rnb-spacing-tiny);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-height: var(--verticalIconImageHeight);
    }
}

.verticalName {
    margin-top: var(--rnb-spacing-medium);
    color: var(--rnb-colour-black-alpha-64);
}

@media (--rnb-mq-medium) {
    .heading {
        margin-bottom: var(--rnb-spacing-large);
    }

    .verticalIcon {
        --verticalIconSizeLargest: 160px;

        width: var(--verticalIconSizeLargest);
        height: var(--verticalIconSizeLargest);
    }
}

@media (--rnb-mq-large) {
    .offerCards {
        grid-template-columns: 1fr 1fr;

        &.fullWidthIcons {
            grid-template-columns: 1fr;

            .verticalIcon {
                width: var(--verticalIconSizeLarger);
                height: var(--verticalIconSizeLarger);
            }
        }
    }

    .heading {
        font-size: var(--rnb-font-size-mini);
        position: absolute;
        top: 1.5em;
    }

    .verticalIcon {
        --verticalIconSizeSmaller: 95px;

        /* reduced phone size */
        width: var(--verticalIconSizeSmaller);
        height: var(--verticalIconSizeSmaller);
        justify-content: space-evenly;
    }

    .verticalName {
        margin-top: 0;
    }

    .phoneInnerContent {
        --phoneBodyHeight: 90%;

        padding-top: var(--rnb-spacing-medium);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        height: var(--phoneBodyHeight);
    }
}

@media (min-width: 1440px) {
    .verticalIcon {
        /* reduced phone size */
        --verticalIconSize: 110px;

        width: var(--verticalIconSize);
        height: var(--verticalIconSize);
    }

    .verticalName {
        margin-top: 0;
    }
}
