.score {
    --scoreValueSize: 3.25rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: var(--rnb-colour-white);
    font-size: var(--scoreValueSize);
    font-weight: var(--rnb-font-weight-thin);
}

.donutText {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--rnb-colour-white);
}

.donutLabel {
    --donutTopContentOffset: 27.5%;

    top: var(--donutTopContentOffset);
    width: 100%;
}

.donutMaxScore {
    --donutBottomContentOffset: 72.5%;

    top: var(--donutBottomContentOffset);
}

.ring {
    --rnb-colour-midnight-tint-4: rgba(255, 255, 255, 0.3);
    --circleRadius__2: 50%;
    --greyOpaqueBackground: rgba(0, 0, 0, 0.2);
    --darkenBackground: rgba(0, 0, 0, 0.5);
    --animateOpacityStart__2: 0.2;
    --animateOpacityMiddle: 0.5;
    --animateOpacityEnd: 1;
    --ringSize__2: 200px;

    margin: 0 auto;
    position: relative;
    border: var(--rnb-border-width-thin) var(--rnb-colour-midnight-tint-4) solid;
    border-radius: var(--circleRadius__2);
    background: var(--greyOpaqueBackground);
    height: var(--ringSize__2);
    width: var(--ringSize__2);

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
    }
}

.marketRing {
    --rnb-colour-midnight-tint-4: rgba(255, 255, 255, 0.3);
    --circleRadius__2: 50%;
    --greyOpaqueBackground: rgba(0, 0, 0, 0.3);
    --darkenBackground: rgba(0, 0, 0, 0.5);
    --animateOpacityStart__2: 0.2;
    --animateOpacityMiddle: 0.5;
    --animateOpacityEnd: 1;
    --ringSize__2: 200px;

    margin: 0 auto;
    position: relative;
    border: var(--rnb-border-width-thin) var(--rnb-colour-midnight-tint-4) solid;
    border-radius: var(--circleRadius__2);
    background: var(--greyOpaqueBackground);
    height: var(--ringSize__2);
    width: var(--ringSize__2);
    overflow: hidden;

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
    }
}

.fadeOpacity {
    --animateDuration__2: 0.7s;

    animation: fadeOpacity var(--animateDuration__2);
}

@keyframes fadeOpacity {
    0% {
        opacity: var(--animateOpacityStart__2);
    }

    50% {
        opacity: var(--animateOpacityMiddle);
    }

    100% {
        opacity: var(--animateOpacityEnd);
    }
}
