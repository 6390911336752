.emailPopupContent {
    min-width: 288px;
}

.emailPopupCTA {
    padding-top: 10px;

    button {
        margin-right: 10px;
    }
}

.suggestionContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;

    .emailSuggestion {
        max-width: 288px;
        display: flex;
        flex-flow: row nowrap;

        :first-child {
            overflow: hidden;
        }
    }
}
